import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

export function Home() {
    const query = new URLSearchParams(useLocation().search);
    const hist = useHistory();

    if (query) {
        const id = query.get('ID');// case-sensitive
        if(id)
            hist.push(`/register/${id}`);
    }
    return (
        <div className="container">
            <div className="card-deck mb-3 text-center">
                <div className="card mb-4 box-shadow">
                    <div className="card-header">
                        <h4 className="my-0 font-weight-normal">Registration</h4>
                    </div>
                    <div className="card-body">
                        <h1 className="card-title pricing-card-title"><small className="text-muted"></small></h1>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>Register for test</li>
                            <li>Enter your information</li>
                            <li>Get the Confirmation code</li>
                        </ul>
                        <Link to="/register" className="btn btn-lg btn-block btn-primary">Register</Link>
                    </div>
                </div>
                <div className="card mb-4 box-shadow">
                    <div className="card-header">
                        <h4 className="my-0 font-weight-normal">Results</h4>
                    </div>
                    <div className="card-body">
                        <h1 className="card-title pricing-card-title"><small className="text-muted"></small></h1>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>Get tested</li>
                            <li>Enter the Confirmation code</li>
                            <li>View your result</li>
                        </ul>
                        <Link to="results" className="btn btn-lg btn-block btn-secondary">Get Results</Link>
                    </div>
                </div>
                <div className="card mb-4 box-shadow">
                    <div className="card-header">
                        <h4 className="my-0 font-weight-normal">Returning Patients</h4>
                    </div>
                    <div className="card-body">
                        <h1 className="card-title pricing-card-title"><small className="text-muted"></small></h1>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>Enter the Confirmation code</li>
                            <li>Click Check-in below</li>
                        </ul>
                        <Link to="retest" className="btn btn-lg btn-block btn-secondary">Check-in</Link>
                    </div>
                </div>
            </div>
        </div>
    );
  }
